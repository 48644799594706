<template>
  <Cond :where="where" @refresh="refresh">
    <div class="mgb10">
      <el-button
        type="primary"
        icon="el-icon-refresh"
        @click="staticShops"
        circle
      ></el-button>
    </div>
    <el-table
      @selection-change="handleSelectionChange"
      :data="tableData"
      style="width: 100%"
      border
      size="small"
      v-loading="where.loading"
      @sort-change="sortChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="shop_name" label="账号信息" width="180">
        <template slot-scope="scope">
          <div>店铺:</div>
          <div class="mgb10">{{ scope.row.shop_name }}</div>
          <div>账号:</div>
          <div class="mgb10">{{ scope.row.user_name }}</div>
          <div>平台:</div>
          <div v-if="scope.row.source == 1" class="mgb10">
            <el-tag type="primary" effect="dark" size="small">SHOPEE</el-tag>
          </div>
          <div v-else-if="scope.row.source == 2" class="mgb10">
            <el-tag type="success" effect="dark" size="small">LAZADA</el-tag>
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="master_id" label="账号ID" width="120">
      </el-table-column>
      <el-table-column prop="shop_id" label="shopID" width="120">
      </el-table-column>
      <el-table-column prop="db_code" label="数据库编码" width="100">
      </el-table-column>
      <el-table-column
        prop="order_last_time"
        sortable="custom"
        label="最新订单时间"
        width="140"
      >
        <template slot-scope="scope">
          {{ $func.format(scope.row.order_last_time) }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="day_order_nums"
        sortable="custom"
        label="今日单量"
        width="100"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="yesterday_order_nums"
        sortable="custom"
        label="昨日单量"
        width="100"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="pre_week_day_order_nums"
        sortable="custom"
        label="上周同日单量"
        width="120"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="week_order_nums"
        sortable="custom"
        label="周单量"
        width="100"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="month_order_nums"
        sortable="custom"
        label="月单量"
        width="100"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="year_order_nums"
        sortable="custom"
        label="年单量"
        width="100"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="order_un_get_item_count"
        sortable="custom"
        label="未抓明细数量"
        width="120"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="order_un_get_item_count_v2"
        sortable="custom"
        label="未抓明细数量2"
        width="130"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="n_day_data_count"
        sortable="custom"
        label="N天前数据量"
        width="130"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="lazada_un_get_document_count"
        sortable="custom"
        label="LAZADA未下载面单量"
        width="180"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="lazada_rts_count"
        sortable="custom"
        label="LAZADA RTS 数量量"
        width="180"
      >
      </el-table-column>
      <el-table-column
        prop="report_time"
        sortable="custom"
        label="统计时间"
        width="140"
      >
        <template slot-scope="scope">
          {{ $func.format(scope.row.report_time) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="auth_time"
        sortable="custom"
        label="店铺授权过期时间"
        width="160"
      >
        <template slot-scope="scope">
          {{ $func.format(scope.row.auth_time) }}
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="100">
        <template slot-scope="scope">
          <div>
            <el-button
              title="刷新"
              icon="el-icon-refresh"
              circle
              @click="staticOneShop(scope.row)"
              size="small"
            ></el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="mgt10 text-center">
      <el-pagination
        @current-change="loadList"
        :current-page.sync="where.page"
        background
        layout="prev, pager, next"
        :page-size="where.pageSize"
        :total="where.total"
      >
      </el-pagination>
    </div>
  </Cond>
</template>
<script>
import Cond from "./shopmonitorcond.vue";
export default {
  components: {
    Cond,
  },
  data() {
    return {
      url: {
        shopmonitorlist: "/sysadminmonitor/shopmonitorlist",
        refreshstatic: "/sysadminmonitor/refreshstatic",
      },
      selectData: [],
      where: {
        shop_name: "",
        user_name: "",
        sort_key: "",
        sort_value: "",
        mealOverTime:-1,
        page: 1,
        pageSize: 20,
        total: 0,
        loading: false,
      },
      tableData: [],
    };
  },
  created() {
    this.loadList();
  },
  methods: {
    handleSelectionChange: function (rows) {
      this.selectData = [];
      for (var i in rows) {
        this.selectData.push(rows[i].id);
      }
    },
    sortChange: function (row) {
      this.where.sort_key = row.prop;
      if (row.order == "ascending") {
        this.where.sort_value = "asc";
      } else {
        this.where.sort_value = "desc";
      }
      this.refresh();
    },
    staticOneShop: function (row) {
      this.staticShop([row.id]);
    },
    staticShops: function () {
      this.staticShop(this.selectData);
    },
    staticShop: function (ids) {
      if (ids.length == 0) {
        this.$message({
          type: "warning",
          message: "请勾选记录",
        });
        return;
      }
      this.where.loading = true;
      this.$rq.postAction(this.url.refreshstatic, { ids: ids }).then((rs) => {
        this.where.loading = false;
        if (rs.code == 1) {
          this.loadList();
        }
      });
    },
    disNow: function (t) {
      var disTotalDate = t - parseInt(new Date().getTime() / 1000);
      if (disTotalDate < 0) {
        return 0;
      }
      return Math.ceil(disTotalDate / (24 * 3600));
    },
    refresh: function () {
      this.where.page = 1;
      this.loadList();
    },
    loadList: function () {
      this.where.loading = true;
      this.$rq.postAction(this.url.shopmonitorlist, this.where).then((rs) => {
        this.where.loading = false;
        if (rs.code == 1) {
          this.tableData = [];
          for (var i in rs.list) {
            this.tableData.push(rs.list[i]);
          }
          this.where.total = parseInt(rs.pageInfo.total);
        } else {
          this.$message({
            type: "warning",
            message: rs.err,
          });
        }
      });
    },
  },
};
</script>