<template>
  <Main>
    <el-card class="box-card">
      <el-form :inline="true" class="demo-form-inline" size="mini">
        <el-form-item label="店铺名称">
          <el-input
            clearable
            placeholder="请输入店铺名称"
            v-model="where.shop_name"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="用户账号">
          <el-input
            clearable
            placeholder="请输入用户账号"
            v-model="where.user_name"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="套餐">
          <el-radio-group v-model="where.mealOverTime" @change="$emit('refresh')">
            <el-radio-button :label="-1">全部</el-radio-button>
            <el-radio-button :label="0">购买套餐</el-radio-button>
            <el-radio-button :label="1">未购买或套餐过期</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button
            @click="$emit('refresh')"
            type="primary"
            icon="el-icon-search"
            circle
          ></el-button>
        </el-form-item>
      </el-form>
      <div>
        <slot></slot>
      </div>
    </el-card>
  </Main>
</template>
<script>
import Main from "@/components/backend/common/main.vue";
export default {
  components: {
    Main,
  },
  props: {
    where: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },
};
</script>